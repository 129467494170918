import topic from '../topic';

export const requAPI = {
  getRequs: topic.requTopic + '/get_requs',
  getRequById: topic.requTopic + '/get_requ_by_id',
  addRequ: topic.requTopic + '/add_requ',
  editRequ: topic.requTopic + '/edit_requ',
  deleteRequByIds: topic.requTopic + '/delete_requ_by_ids',
  BackRequById: topic.requTopic + '/back_requ_by_id', // 退回的接口
  ReceiveRequById: topic.requTopic + '/receive_requ_by_id', // 接收的接口
  getRequPush: topic.requTopic + '/getRequPush', // 获取委托接收单通知list
  updateRequPushTotal: topic.requTopic + '/updateRequPushTotal', // 更改接受数字接口

  get_all_requs_v1: topic.requTopic + '/get_all_requs_v1', //开卡工序委托单号
  getPushRequMails9003: topic.requTopic + '/get_push_requ_mail_9003', // 获取所有requ的邮件情况
  updateScanById: topic.requTopic + '/update_requ_scan_by_id', // 重发的接口
  sendRqtaToPC: topic.requToPC + '/sendRqtaToPC', // 同步至蒲橙接口
  PcTodyj: topic.requToPC + '/getGcdyd', // 获取工厂打样单
  get_docu_push_9007: topic.requTopic + '/get_docu_push_9007', //
  edit_docu_push_9007: topic.requTopic + '/edit_docu_push_9007', //
  get_cancelsmpl_push_9008: topic.requTopic + '/get_cancelsmpl_push_9008', //
  edit_docu_push_9008: topic.requTopic + '/edit_docu_push_9008', //
  export_requ_by_id: topic.requTopic + '/export_requ_by_id' // 导出excel
};
